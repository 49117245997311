.container-loading {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.container-loading .spinner-medium {
  width: 3rem;
  height: 3rem;
}

.spiner-medium {
  width: 3rem;
  height: 3rem;
}

.spiner-small {
  width: 2rem;
  height: 2rem;
}
